import Link from 'next/link'
import { memo } from 'react'

import SiteLogo from '@/components/SiteLogo'
import { PATHS } from '@/configs/paths'

type SimpleSiteHeaderProps = {
  links?: {
    href: string
    title: string
    isExternal?: boolean
  }[]
}

const SimpleSiteHeader = ({ links }: SimpleSiteHeaderProps) => (
  <div className="sticky top-0 z-20 p-3 bg-white shadow-sm">
    <header className="flex items-center mx-auto max-w-[85rem]">
      <div className="flex flex-1 justify-center">
        {links?.length > 0 && (
          <ul className="mr-auto">
            {links?.map((link) => (
              <li
                key={link.title}
                className="font-sans text-sm font-bold tracking-wider hover:text-primary-dark uppercase"
              >
                {link?.isExternal ? (
                  <a
                    href={link.href}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="block py-2 px-3 w-full"
                  >
                    {link.title}
                  </a>
                ) : (
                  <Link href={link.href} className="block py-2 px-3 w-full">
                    {link.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="flex flex-1 justify-center">
        <Link href={PATHS.BASE} className="cursor-pointer">
          <SiteLogo />
        </Link>
      </div>

      <div className="flex flex-1 justify-center" />
    </header>
  </div>
)

export default memo(SimpleSiteHeader)
