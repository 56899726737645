import { memo } from 'react'

import Error404 from '@/components/Error404'
import SiteHead from '@/components/SiteHead'

const Error500Page = () => (
  <>
    <SiteHead title="Not Found" />
    <Error404 />
  </>
)

export default memo(Error500Page)
