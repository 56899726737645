import { memo, useMemo } from 'react'

import ButtonLink from '@/components/ButtonLink'
import SimpleSiteHeader from '@/components/SimpleSiteHeader'
import SiteFooter from '@/components/SiteFooter'
import { PATHS } from '@/configs/paths'
import { useWindowSize } from '@/hooks'

const Error404 = () => {
  const { isMobile } = useWindowSize()

  const links = useMemo(
    () => [
      {
        href: PATHS.BASE,
        title: isMobile ? 'Home' : 'Back To Home'
      }
    ],
    [isMobile]
  )

  return (
    <div className="flex flex-col min-h-screen">
      <SimpleSiteHeader links={links} />

      <main className="p-3 text-center text-white bg-bottom bg-no-repeat bg-cover grow pt-[7.5rem] md:pt-[16.25rem] pb-[17.875rem] md:pb-[24.25rem] bg-[url(/images/suvie-delivery.jpg)]">
        <div className="mx-auto md:max-w-none max-w-[16.25rem]">
          <h1 className="mb-3 md:mb-7 font-serif font-medium text-[4rem]">
            404
          </h1>

          <p className="mx-auto mb-3 md:mb-7 max-w-lg text-sm md:text-base">
            The page you were looking for does not exist.{' '}
            <br className="hidden md:block" />
            Just click below to go back to the planner.
          </p>

          <ButtonLink
            href={PATHS.BASE}
            className="md:py-4 max-w-full md:text-base w-[20.25rem]"
          >
            Go back home
          </ButtonLink>
        </div>
      </main>

      <div className="hidden md:block -mt-12">
        <SiteFooter />
      </div>
    </div>
  )
}

export default memo(Error404)
