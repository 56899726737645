import Link from 'next/link'
import { LinkHTMLAttributes, memo, useMemo } from 'react'

import { TButtonTheme } from '@/types'
import { getButtonClassnames } from '@/utils/misc'

type ButtonLinkProps = {
  theme?: TButtonTheme
  target?: string
  isExternal?: boolean
  className?: string
} & LinkHTMLAttributes<HTMLAnchorElement>

/**
 * @deprecated Use the {@see [Button](../Button/Button.tsx)} component instead. It's now polymorphic.
 */
const ButtonLink = ({
  children,
  href,
  theme = 'primary',
  target = '_self',
  isExternal,
  className,
  ...props
}: ButtonLinkProps) => {
  const linkClassName = useMemo(
    () => getButtonClassnames(theme, className),
    [theme, className]
  )

  if (isExternal) {
    return (
      <a
        href={href}
        className={linkClassName}
        rel={target === '_blank' ? 'noreferrer noopener' : null}
        target={target}
        {...props}
      >
        {children}
      </a>
    )
  }

  return (
    <Link href={href} className={linkClassName}>
      {children}
    </Link>
  )
}

export default memo(ButtonLink)
